import { Container, Form, Row, Button, Card } from "react-bootstrap";
import Layout from "../components/Layout";
import Facebook from "../components/Facebook";
import Google from "../components/Google";
import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { saveUser } from "../utils/auth";
export default function UserLoginPage() {
  const handleSubmit = (e) => {
    let usernameinputvalue = document.getElementById("MyEmail1").value;
    let passwordinputvalue = document.getElementById("MyPassword1").value;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=F8WF1tse1m4jPeVmq_y8v1PigUs5Dd7FfbcqJis39hCNEd1j5bfcb5Of5jeqAps4iebShIkJsGxTzQyGDEnIEqIUmZdG5PJzsXSHx2OS8UvrB-N8u1DhQeMksnPhA2IiV9j2GcDR-BIfGoJCvztFJRfnwSNhDftqKTUDcA68GTeji3c1fPA0VCIO7GdUeJ20Cu-VsZ5YqlTd7kjn15jHBF5xKy2_xuxvSy-UXNE_H9PEasutlUqZBrqVSOgRiAFC3aw_bZ9A1f-ER3L3apeVplyTs2gA_RbKd7JFhiKDiUvLHaBhWKbAu56hYy1_4CDnZBXqvGi5rdFe3siOTawWwlGj-qNhCStSuCDk2SL-DXmgktBbDYgpEF9KU_9Qv2zcahH5ncA1WUiFbkykRsowRvIXsOOxUIrtEcrEhi7DSv1ThsRP_MmGkK92wgjXcXwtAsUJOvK9VnrdWnGMOayErv02949RlnYs5oqHNiV_YVi7j9eC0jFAje45YrEoFrBgQJaHG0RIndTeD6GHaK3vQg"
    );

    var urlencoded = new URLSearchParams();
    urlencoded.append("password", passwordinputvalue);
    urlencoded.append("username", usernameinputvalue);
    urlencoded.append("grant_type", "password");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/token`, requestOptions)
      .then((response) => {
        /* if (response.status === 200) {
          navigate("/dashboard");
        } */
        return response?.text() ?? "";
      })

      .then((result) => {
        var json = JSON.parse(result);
        if (
          json?.error_description === "The user name or password is incorrect."
        ) {
          document.querySelector(".checkemailerror").style.display = "block";
        } else {
          document.querySelector(".checkemailerror").style.display = "none";
        }

        if (json.access_token) {
          localStorage.setItem("loginaccesstokec", json.access_token);
          localStorage.setItem("loginemail", json.userName);

          saveUser({
            name: json?.userName ?? "",
            email: json?.userName ?? "",
            token: json.access_token ?? "",
          });
          navigate(`/app/dashboard`);

          document.querySelector(".registerbutton").style.display = "none";
          document.querySelector(".loginbutton").style.display = "none";
          document.querySelector(".logoutbutton").style.display = "block";
          document.querySelector(".welocmetext").style.display = "block";
          document.getElementById("Name").innerHTML =
            localStorage.getItem("loginemail");
        }
      })
      .catch((error) => console.log("error", error));
    e.preventDefault();
  };

  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-4 col-lg-4 col-md-12 pb-5 pt-3 mx-auto text-center">
              <form
                className="my-4 text-start"
                onSubmit={handleSubmit}
                action="/dashboard"
              >
                <p
                  className="checkemailerror"
                  style={{ display: "none", fontSize: "11px", color: "red" }}
                >
                  The user name or password is incorrect.
                </p>

                <div className="mb-2">
                  <Form.Group controlId="formGridEmail" className="mb-4">
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      E-mail address
                    </Form.Label>
                    <Form.Control id="MyEmail1" type="email" />
                  </Form.Group>

                  <Form.Group
                    controlId="formGridPassword"
                    className=" pass-wrapper"
                  >
                    <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                      Password
                    </Form.Label>
                    <Form.Control id="MyPassword1" type="password" />
                  </Form.Group>
                </div>
                <p className="primary-color fw-500 mb-2">
                  {" "}
                  <a href="/forgotpassword">Forget Password</a>
                </p>
                <Button
                  variant="primary"
                  className="w-100 fs-14 fw-bold h-52"
                  type="submit"
                >
                  Submit
                </Button>
              </form>
              <Row>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-4">
                  <Card className="border-0 card-login">
                    <Card.Body className="googlecss">
                      <Google />
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-4">
                  <Card className="border-0  card-login">
                    <Card.Body className="facebookcss">
                      <Facebook />
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
